import React from 'react'
import { Container as div } from 'react-bootstrap'
import '../styles/Body.css'
import MainBody from './MainBody'
import Navigation from './Navigation'

export default function Body () {
  return (
    <div className='body'>
      <Navigation />
      <MainBody />
    </div>
  )
}
