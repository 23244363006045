import React, { useState } from 'react'
import '../styles/Connection.css'
import Avatar from './Avatar'
import Label from './Label'

export default function Connection ({ site, link, description, icon, labels, links }) {
  const [isOpened, setOpened] = useState(false)
  return (
    <div className='connection'>
      <Avatar src={icon} />

      <div className='body'>
        <span>
          {site}
        </span>

        <p>{description}</p>
      </div>

      <div className='labels'>
        {labels.map((label) => (<Label key={label} text={label} />))}
      </div>

      <div className='link-header' onClick={() => setOpened(!isOpened)}>
        <p>Links</p>
      </div>

      {
        isOpened
        &&
        (
          <div className='links'>
            <div className='link'>
              <a href={link}>
                {link}
              </a>
            </div>

            {links.map((linkL) => (
              <div className='link'>
                <a href={linkL}>
                  {linkL}
                </a>
              </div>)
            )}
          </div>
        )
      }
    </div>
  )
}
