import React, { useState } from 'react'
import Lieutenant from '../../images/lieutenant.png'
import ExtraTAN from '../../images/extratan.png'
import MMOParties from '../../images/mmoparties.png'
import R24 from '../../images/riitag.svg'
import MHW from '../../images/mhw.webp'
import Section from '../Section'
import Connection from '../Connection'
import TabbedSection from '../TabbedSection'
import { Badge } from 'react-bootstrap'

export default function Projects() {
  const subTab = "Curseforge"
  return (
    <TabbedSection>
      <Section.Header>
        <span>Projects <Badge>5 Projects</Badge></span>
      </Section.Header>
      <Section.Body>
        <TabbedSection.Content active={subTab === 'Curseforge'} tab='Curseforge'>
          <Projects.CurseForge />
        </TabbedSection.Content>

        <TabbedSection.Content active={subTab === 'Nexus'} tab='Nexus'>
          <Projects.Nexus />
        </TabbedSection.Content>

        <TabbedSection.Content active={subTab === 'Misc'} tab='Misc'>
          <Projects.Misc />
        </TabbedSection.Content>
      </Section.Body>
    </TabbedSection>
  )
}

Projects.CurseForge = function CurseForge() {
  return (
    <React.Fragment>
      <Connection
        site='Lieutenant'
        link='https://www.curseforge.com/minecraft/mc-mods/lieutenant'
        description='The main core mod for all of my projects.'
        icon={Lieutenant}
        labels={['Minecraft', 'Library', 'API', 'Multi-Version']}
        links={[]}
      />
      <Connection
        site='Extra TAN'
        link='https://www.curseforge.com/minecraft/mc-mods/extra-tan'
        description="An expansion to Tough As Nails"
        icon={ExtraTAN}
        labels={['Minecraft', 'Expansion', 'Tough as Nails', 'Multi-Version']}
        links={[]}
      />
      <Connection
        site='RPG Parties'
        link='https://www.curseforge.com/minecraft/mc-mods/rpg-parties'
        description="Allows you to join parties with your friends to track their status!"
        icon={MMOParties}
        labels={['Minecraft', 'GUI', 'MMO', 'Multiplayer', 'Multi-Version']}
        links={[]}
      />
      <Connection
        site='RPG Parties - Tough as Nails'
        link='https://www.curseforge.com/minecraft/mc-mods/rpg-parties-tough-as-nails'
        description="Allows you to join parties with your friends to track their thirst!"
        icon={MMOParties}
        labels={['Minecraft', 'GUI', 'MMO', 'Multiplayer', 'Multi-Version','Compatibility']}
        links={[]}
      />
    </React.Fragment>
  )
}

Projects.Nexus = function Nexus() {
  return (
    <React.Fragment>
      <Connection
        site='MHW Rich Presence'
        link='https://www.nexusmods.com/monsterhunterworld/mods/3023'
        icon={MHW}
        labels={['Monster Hunter World', 'Rich Presence']}
      />
    </React.Fragment>
  )
}

Projects.Misc = function Misc() {
  return (
    <React.Fragment>
      <Connection
        site='RiiTag'
        link='https://tag.rc24.xyz'
        icon={R24}
        description='A simple tool to display your currently played game and history on Wii consoles'
        labels={['Nintendo', 'Wii U', '3DS', 'Developer']}
      />
    </React.Fragment>
  )
}
