import React, { useEffect, useState } from 'react'
import AssociatedSites from './sections/AssociatedSites'
import Projects from './sections/Projects'
import { Button } from 'react-bootstrap'
import StarieLogo from '../images/starielogo.png'

import '../styles/Section.css'
import '../styles/Premiere.css'

const CAROUSEL = [
  <PremiereProject icon={StarieLogo} title='Starie' description='My custom-designed, extremely fast Discord bot designed to rival the likes of Vortex and Dyno.\n It&quot;s completely free, forever, and free from NFTs and the blockchain.' buttons={[{ text: 'Privacy Policy', value: 'https://matthe815.dev/starie/privacy' }]} />,
]

function Tier ({ name, perks, price, color }) {
  return (
    <div className='tier' style={{ borderColor: color }}>
      <p>{name}</p>
      <div className="perks">
        {
          perks.map((perk) => (
            <tr>{perk}</tr>
          ))
        }
      </div>
      <div className="prices">
        <Button style={{ backgroundColor: color }}>{price[0]} (Monthly)</Button>
        <Button style={{ backgroundColor: color }}>{price[1]} (Yearly)</Button>
      </div>
    </div>
  )
}

function Changelog ({ version }) {
  return (
    <div className="changelog" style={{ width: "60%" }}>
      <p>{version}</p>
    </div>
  )
}

function PremiereProject ({icon, title, description, buttons}) {
  return (
    <div className='premiere'>
      <div className='premiere-texts'>
        <div className='premiere-icon'>
          <img src={icon} width={128} />
        </div>
        <div className='premiere-text'>
          <h5 className='premiere-title'>{title}</h5>
          {description}
        </div>
      </div>
      <div className='buttons'>
        {buttons.map((button) => <a href={button.value}>{button.text}</a>)}
      </div>
    </div>
  )
}

function PremiereCarousel ({ items }) {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      setIndex(index + 1 < items.length ? index+1 : 0)
    }, 10000)
  })

  return items[index]
}

export default function MainBody () {
  return (
    <div className='content'>
      <div className='inline' style={{ justifyContent: 'center', minHeight: '100vh', alignItems: 'center' }}>
        <PremiereCarousel items={CAROUSEL} />
      </div>
      <h3>Check out my projects!</h3>
      <div className='inline'>
        <div className='column'>
          <Button>Join Discord</Button>
          <Projects />
        </div>
        <div className='column'>
          <Button>Donate on Patreon</Button>
          <AssociatedSites />
        </div>
      </div>
    </div>
  )
}
